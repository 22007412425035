import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  Back,
  BluePencil,
  Export,
  girl,
  TransferIcon
} from "../../../../assets";
import {
  ButtonBack,
  ButtonBackground,
  ButtonSalvar,
  ContainerLeftModal,
  ContainerRightModal,
  DivIcon,
  DivRight,
  DivTextButton,
  H1,
  HeaderDiv,
  IconButton,
  IconButtonBackground,
  LeftHeaderWhiter,
  Options
} from "./styles";
import { setBotIdActive } from "../../../../redux/slices/flowInteractions";
import BotsServices from "../../../../services/bots";
import {
  setActive,
  setButtonEditScript,
  setCompanyId,
  setDefaultFallBackMessage,
  setFirstBotInteractions,
  setName,
  setTarget,
  setWorkspaceId
} from "../../../../redux/slices/bot";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { useEffect, useState } from "react";
import { useToast } from "../../../../components/toast-provider";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import WorkspaceUserService from "../../../../services/workspaceUserService";

export const HeaderGestaoBot: React.FC = () => {
  const redirect = useNavigate();
  const dispatch = useAppDispatch();
  const BotActiveId = useAppSelector(
    (state) => state.flowInteractions.botIdActive
  );
  const valueTarget = useAppSelector((state) => state.bot.target);
  const botObject = useAppSelector((state) => state.bot);
  const [loading, setloading] = useState(false);
  const { GetBotResume, UpdateBot, GetBotTransfer, updateBotTransfer } =
    BotsServices();
  const { company } = useAuth() as AuthContextType;
  const { botId } = useParams();
  const toast = useToast();
  const [showDialogTransfer, setShowDialogTransfer] = useState(false);
  const [dataBotTransfer, setDataBotTransfer] = useState({
    workspaceId: null,
    workspaceName: null,
    userId: null,
    userName: null,
    transferDialogInitiatedByBot: false
  });
  const [transferType, setTransferType] = useState("");
  const workspaceList = useAppSelector((state) => state.bot.workspaceList);
  const showButtonEditScript = useAppSelector(
    (state) => state.bot.buttonEditScript
  );
  const { user } = useAuth() as AuthContextType;
  const { companyId } = user;
  const { getUsersByWorkspace } = WorkspaceUserService();
  const [users, setUsers] = useState([]);
  const [
    showTextDigitalEmployeeOrNoScript,
    setShowTextDigitalEmployeeOrNoScript
  ] = useState(false);

  useEffect(() => {
    const BotId = botId ? parseInt(botId) : 0;
    const getResumeBot = async () => {
      dispatch(setBotIdActive(BotId));
    };
    if (!isNaN(BotId)) {
      getResumeBot();
    }
  }, [botId]);

  const handleEditarScript = async (BotId: number) => {
    dispatch(setBotIdActive(BotId));
    const activeFlowId = await GetBotResume(BotId);
    dispatch(setName(activeFlowId?.name));
    dispatch(setActive(activeFlowId?.active));
    dispatch(setTarget(activeFlowId?.target));
    dispatch(setDefaultFallBackMessage(activeFlowId?.defaulFallbackMessages));
    dispatch(setCompanyId(company?.id));
    dispatch(setWorkspaceId(activeFlowId?.workspaceId));
    dispatch(setFirstBotInteractions(activeFlowId?.liveChatInteractions));

    redirect(`/drawflow/${BotId}/${activeFlowId?.activeBotFlowId}`);
  };

  const handleTransfer = async (BotId: number) => {
    setShowDialogTransfer(true);
    const DataBotTransfer = await GetBotTransfer(BotId);
    setDataBotTransfer(DataBotTransfer);
  };

  const handleDropdownChange = (field: string, value: any) => {
    setDataBotTransfer((prevState) => ({
      ...prevState,
      [field]: value
    }));
  };

  const updateUsersList = async () => {
    if (dataBotTransfer.workspaceId) {
      const users = await getUsersByWorkspace(
        companyId,
        dataBotTransfer.workspaceId
      );
      setUsers(users);
    }
  };

  useEffect(() => {
    if (dataBotTransfer.workspaceId && transferType === "user") {
      updateUsersList();
    }
  }, [dataBotTransfer.workspaceId, transferType]);

  const handleBotTransfer = async () => {
    if (!dataBotTransfer.workspaceId) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro",
        detail: `É preciso selecionar um workspace no campo "Selecione um workspace"`
      });
      return;
    }
    if (transferType === "user" && !dataBotTransfer.userId) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro",
        detail: `É preciso selecionar um usuário no campo "Usuário"`
      });
      return;
    }
    try {
      setloading(true);

      const dataToSend: {
        workspaceId?: number;
        userId?: number;
      } = {
        workspaceId:
          dataBotTransfer.workspaceId !== null
            ? dataBotTransfer.workspaceId
            : undefined,
        userId:
          transferType === "user"
            ? dataBotTransfer.userId !== null
              ? dataBotTransfer.userId
              : undefined
            : undefined
      };

      await updateBotTransfer(BotActiveId, dataToSend);

      toast?.current?.show({
        life: 5000,
        severity: "success",
        summary: "Sucesso",
        detail: "Configurações de transferência atualizadas com sucesso!"
      });
    } catch (error: any) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro",
        detail: error.message
      });
    } finally {
      setloading(false);
    }
    setShowDialogTransfer(false);
  };

  const handleSave = async () => {
    if (
      botObject.botAutomationDto?.botRepeatAutomationDto.minutes &&
      botObject.botAutomationDto?.botRepeatAutomationDto.minutes > 0 &&
      botObject.botAutomationDto?.botRepeatAutomationDto.times < 1
    ) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro",
        detail: "Preencha todos os campos da automação de refazer pergunta"
      });
    } else if (
      botObject.botAutomationDto?.botTransferAutomationDto.minutes !== 0 &&
      botObject.botAutomationDto?.botTransferAutomationDto.queue === "USER" &&
      botObject.botAutomationDto?.botTransferAutomationDto.userId === 0
    ) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro",
        detail: "Preencha todos os campos da transferência de salas"
      });
    } else if (
      botObject.botAutomationDto?.botTransferAutomationDto.minutes !== 0 &&
      botObject.botAutomationDto?.botTransferAutomationDto.queue ===
        "SEQUENTIAL"
    ) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro",
        detail: "Preencha todos os campos da transferência de salas"
      });
    } else if (
      botObject.botAutomationDto?.botCloseRoomAutomationDto.minutes !== 0 &&
      botObject.botAutomationDto?.botCloseRoomAutomationDto.closeRoomType ===
        null
    ) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro",
        detail: "Preencha todos os campos do fechamento de salas"
      });
    } else {
      try {
        setloading(true);
        const newbotObject = botObject;
        const res = await UpdateBot(BotActiveId, newbotObject);
        if (res.id) {
          toast?.current?.show({
            life: 5000,
            severity: "success",
            summary: "Sucesso",
            detail: "Dados atualizados com sucesso!"
          });
        }
      } catch (error: any) {
        toast?.current?.show({
          life: 5000,
          severity: "error",
          summary: "Erro",
          detail: error.message
        });
      } finally {
        setloading(false);
      }

      const activeFlowId = await GetBotResume(Number(botId));
      if (activeFlowId.target !== 4 && activeFlowId.target !== 6) {
        dispatch(setButtonEditScript(true));
      } else {
        dispatch(setButtonEditScript(false));
      }

      setloading(false);
    }
    // try {
    //   setloading(true);
    //   const newbotObject = botObject;
    //   const res = await UpdateBot(BotActiveId, newbotObject);
    //   if (res.id) {
    //     toast?.current?.show({
    //       life: 5000,
    //       severity: "success",
    //       summary: "Sucesso",
    //       detail: "Dados atualizados com sucesso!"
    //     });
    //   }
    // } catch (error: any) {
    //   toast?.current?.show({
    //     life: 5000,
    //     severity: "error",
    //     summary: "Erro",
    //     detail: error.message
    //   });
    // } finally {
    //   setloading(false);
    // }

    // const activeFlowId = await GetBotResume(Number(botId));
    // if (activeFlowId.target !== 4 && activeFlowId.target !== 6) {
    //   dispatch(setButtonEditScript(true));
    // } else {
    //   dispatch(setButtonEditScript(false));
    // }

    // setloading(false);
  };

  useEffect(() => {
    if (dataBotTransfer.userId === null) {
      setTransferType("bot");
    } else {
      setTransferType("user");
    }
  }, [dataBotTransfer.userId]);

  useEffect(() => {
    if (!dataBotTransfer.workspaceId) {
      setDataBotTransfer((prevData) => ({
        ...prevData,
        userId: null
      }));
    }
  }, [dataBotTransfer.workspaceId]);

  useEffect(() => {
    if (valueTarget === 4 || valueTarget === 6) {
      setShowTextDigitalEmployeeOrNoScript(true);
    } else {
      setShowTextDigitalEmployeeOrNoScript(false);
    }
  });

  return (
    <div css={HeaderDiv}>
      <button
        type="button"
        css={ButtonBack}
        onClick={() => redirect("/chatbots")}
      >
        <img src={Back} alt="Button Back" />
      </button>
      <h1 className="ml-2" css={H1}>
        Gestão do Bot
      </h1>
      <div css={DivRight}>
        <button
          type="button"
          onClick={() => handleTransfer(BotActiveId)}
          className="ml-4"
          css={ButtonBackground}
        >
          <div css={DivIcon}>
            <img
              css={IconButtonBackground}
              src={TransferIcon}
              alt="IconButton Blue Pencil"
            />
          </div>
          <div css={DivTextButton}>Transferência</div>
        </button>

        {showButtonEditScript && (
          <button
            type="button"
            onClick={() => handleEditarScript(BotActiveId)}
            className="ml-4"
            css={ButtonBackground}
          >
            <div css={DivIcon}>
              <img
                css={IconButtonBackground}
                src={BluePencil}
                alt="IconButton Blue Pencil"
              />
            </div>
            <div css={DivTextButton}>Editar Script</div>
          </button>
        )}

        <button
          type="button"
          disabled={loading}
          onClick={handleSave}
          css={ButtonSalvar}
        >
          Salvar
        </button>
      </div>
      <Dialog
        className="addWspaceDialog relative"
        style={{ border: "none" }}
        headerStyle={{
          position: "relative",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
          border: "none",
          height: "3rem",
          paddingTop: "1.5rem",
          backgroundColor: "#ff0025"
        }}
        visible={showDialogTransfer}
        contentStyle={{
          padding: 0,
          height: "50vh",
          maxWidth: "70vw",
          borderBottomLeftRadius: "1rem",
          borderBottomRightRadius: "1rem",
          border: "none",
          overflow: "hidden"
        }}
        onHide={() => setShowDialogTransfer(false)}
        closeOnEscape={true}
        resizable={false}
        closable={true}
        keepInViewport={false}
        draggable={false}
      >
        <LeftHeaderWhiter />
        <ContainerLeftModal>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              height: "100%"
            }}
          >
            <div style={{ alignItems: "center", display: "flex", gap: "10px" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    color: "#0A043C",
                    fontSize: "1.02rem !important",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px"
                  }}
                >
                  <div css={DivIcon}>
                    <img
                      css={IconButtonBackground}
                      src={TransferIcon}
                      alt="IconButton Blue Pencil"
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontWeight: "bold" }}>
                      Configurações de transferência de sala
                    </div>
                    <div style={{ fontSize: "12px", color: "#544F77" }}>
                      Informe os dados de transferência de salas.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between"
              }}
            >
              <Options>
                <Dropdown
                  id="workspaceId"
                  value={dataBotTransfer.workspaceId}
                  options={workspaceList}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Selecione um workspace"
                  onChange={(e) => {
                    handleDropdownChange("workspaceId", e.value);
                  }}
                  filter={true}
                  showClear={true}
                />

                <Dropdown
                  id="transferType"
                  value={transferType}
                  options={[
                    { label: "Sequencial", value: "bot" },
                    { label: "Usuário específico", value: "user" }
                  ]}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Selecione o tipo de transferência"
                  onChange={(e) => {
                    setTransferType(e.value);
                  }}
                />
                {transferType === "user" && dataBotTransfer.workspaceId && (
                  <Dropdown
                    id="userId"
                    value={dataBotTransfer.userId}
                    options={users}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Usuário"
                    onChange={(e) => handleDropdownChange("userId", e.value)}
                    filter={true}
                    showClear={true}
                  />
                )}
              </Options>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  type="button"
                  disabled={loading}
                  onClick={handleBotTransfer}
                  css={ButtonSalvar}
                >
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </ContainerLeftModal>
        <ContainerRightModal>
          <div className="imageTitle">
            <img src={girl} />
            <div>Dica para transferência de salas</div>
          </div>
          <div className="text">
            {showTextDigitalEmployeeOrNoScript ? (
              <>
                Utilize esta configuração para definir o workspace e o usuário
                de destino para transferência automática de conversas iniciadas
                ou não por bots sem script. Permite direcionar salas para
                equipes específicas conforme necessário.
              </>
            ) : (
              <>
                Utilize esta configuração para definir o workspace e o usuário
                de destino para transferência automática de conversas iniciadas
                pelo seu dispositivo móvel. Permite direcionar salas para
                equipes específicas conforme necessário
              </>
            )}
          </div>
        </ContainerRightModal>
      </Dialog>
    </div>
  );
};
