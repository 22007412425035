import { Contacts } from "../../assets";
import { css } from "@emotion/react";
import { Column } from "primereact/column";
import { Button, DataTable } from "..";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import * as comunCss from "../comum_css";
import { Avatar } from "primereact/avatar";
// import useCompany from "./use-company";
// import EditCompany from "../EditCompany";
import {
  ButtonCreate,
  Header,
  Content,
  ContainerPage,
  Container,
  ContainerLeft
} from "./styles";
// import { Identification } from "../header/components/Identification";
import useContacts from "./use-contacts";
import { useAuth } from "../../auth/auth-provider";
import { useAppSelector } from "../../redux/hooks";
import { Sidebar } from "primereact/sidebar";
import { base64ToBlob } from "../../util/base64ToBlob";
import { blobToBase64 } from "../../util/blobToBase64";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { v } from "../../util";
import { useToast } from "../toast-provider";

interface IUserDataValidation {
  name: string;
  email: string;
  phone: string;
}

const UserAvatar = ({
  label,
  avatarFile,
  onChange
}: {
  label: string;
  avatarFile?: File;
  onChange: (value?: File) => void;
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!avatarFile) {
      setPreview(undefined);
      return;
    }

    let objectUrl = URL.createObjectURL(avatarFile);

    objectUrl = URL.createObjectURL(avatarFile);

    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [avatarFile]);

  return (
    <>
      <Avatar
        css={comunCss.avatarCss}
        label={label}
        className="mr-2"
        size="xlarge"
        shape="circle"
        onClick={() => inputFileRef.current?.click()}
        image={preview}
        style={{ left: "8rem" }}
      />
      <input
        type="file"
        css={css`
          display: none;
        `}
        ref={inputFileRef}
        onChange={(e) => {
          if (!e.target.files || e.target.files.length === 0) {
            onChange(undefined);
          } else {
            onChange(e.target?.files?.[0]);
          }
        }}
      />
    </>
  );
};

const ContactsList = () => {
  const { SearchContacts, ListContacts, UpdateContacts } = useContacts();

  const [tablePropsData, setTablePropsData] = useState({});
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [contacts, setContacts] = useState([]);
  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    phone: "",
    workspace: "",
    id: null,
    workspaceId: null
  });
  const [visibleSidebar, setVisibleSidebar] = useState(false);
  const [characterAvatar, setCharacterAvatar] = useState("");
  const toast = useToast();
  const selectedWorkspacesIds = useAppSelector(
    (state) => state.workspaces.selectedWorkspaces
  );
  const [avatarFile, setAvatarFile] = useState<File>();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  //   const [visible, setVisible] = useState(false);
  //   const [valueId, setValueId] = useState(0);

  //   const [first, setFirst] = useState(0);
  //   const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<IUserDataValidation>({
    defaultValues: {
      name: "",
      email: "",
      phone: ""
    },
    resolver: zodResolver(
      v.object({
        name: v.string().superRefine(v.nonempty()),
        email: v.string().superRefine(v.nonempty()),
        phone: v.string().refine((value) => {
          if (value.startsWith("55")) {
            return /^\d{13}$/.test(value);
          }
          return /^\d{9,}$/.test(value);
        })
      })
    )
  });

  useEffect(() => {
    reset({
      name: contactDetails.name || "",
      email: contactDetails.email || "",
      phone: contactDetails.phone || ""
    });
  }, [contactDetails, reset]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 20,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" }
    }
  });

  // const onPage = (event: never) => {
  //   setLazyParams(event);
  // };

  // const onRowsChange = (event) => {
  //   setRows(event.value);
  //   setLazyParams((prevParams) => ({
  //     ...prevParams,
  //     rows: event.value,
  //     first: 0
  //   }));
  // };

  const fetchContacts = async () => {
    if (selectedWorkspacesIds && selectedWorkspacesIds.length > 0) {
      const data = await ListContacts({
        workspaces: selectedWorkspacesIds,
        description: lazyParams.filters.name.value,
        offset: lazyParams.first,
        limit: lazyParams.rows
      });

      setContacts(data);

      if (data.image) {
        const type = data.image.substring(4, data.image.indexOf("base64"));

        const blob = base64ToBlob(
          data.image.replace(/data.+?base64/u, ""),
          type
        );

        const file = new File([blob], type.replace("/", "."), {
          lastModified: new Date().getTime(),
          type
        });

        setAvatarFile(file);
      }
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [selectedWorkspacesIds, lazyParams, selectedContactId]);

  useEffect(() => {
    setLazyParams((prevParams) => ({
      ...prevParams,
      filters: {
        ...prevParams.filters,
        name: { value: globalFilterValue, matchMode: "contains" }
      }
    }));
  }, [globalFilterValue]);

  const openSidebar = async (contact: any) => {
    const contactData = await SearchContacts(contact.id);
    setSelectedContactId(contact.id);
    setContactDetails(contactData);
  };

  const onCloseSidebar = () => {
    setSelectedContactId(null);
  };

  const getFirstNameLeter = (name: string) => {
    setCharacterAvatar(name[0].toUpperCase());
  };

  useEffect(() => {
    if (contactDetails.name) {
      setCharacterAvatar(contactDetails.name[0].toUpperCase());
    }
  }, [contactDetails.name]);

  const sendUpdate = async () => {
    if (selectedContactId) {
      try {
        await UpdateContacts(selectedContactId, contactDetails);
        onCloseSidebar();
        setVisibleSidebar(false);
        toast?.current?.show({
          life: 5000,
          severity: "success",
          summary: "Sucesso",
          detail: "Contato atualizado com sucesso!"
        });
      } catch (error) {
        toast?.current?.show({
          life: 3000,
          severity: "error",
          summary: "Erro.",
          detail: "Erro ao atualizar o contato Por favor tente mais tarde.",
          sticky: true
        });
      }
    }
    setSelectedContactId(null);
  };

  return (
    <ContainerPage>
      <div>
        <div className="p-5 gap-2">
          <Header>
            <div
              css={comunCss.TitleContent}
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <img src={Contacts} width="25px" />
              Contatos
            </div>
          </Header>

          <Content>
            <div className="divTitle">Gerencie os contatos cadastrados.</div>

            <div className="divTitle">
              <div className="divFilter">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    value={globalFilterValue}
                    onChange={(e) => {
                      setGlobalFilterValue(e.target.value);
                      setFirst(0);
                    }}
                    id="name"
                    style={{
                      height: "100%",
                      width: "400px"
                    }}
                    placeholder="Buscar"
                  />
                </span>
              </div>

              <DataTable
                {...tablePropsData}
                value={contacts}
                paginator
                rows={rows}
                filterDelay={600}
                emptyMessage="Nenhum resultado encontrado"
                onSelectionChange={(e) => setSelectedContacts(e.value)}
                selection={selectedContacts}
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "60px" }}
                  alignHeader="center"
                  align="center"
                />

                <Column
                  field="name"
                  header="Nome"
                  headerStyle={{ width: "250px" }}
                  alignHeader="center"
                  // align="center"
                />

                <Column
                  field="email"
                  header="E-mail"
                  headerStyle={{ width: "300px" }}
                  alignHeader="center"
                />

                <Column
                  field="phone"
                  header="Telefone"
                  headerStyle={{ width: "200px" }}
                  alignHeader="center"
                  // align="center"
                />

                <Column
                  header="Ações"
                  headerStyle={{ width: "120px" }}
                  align="center"
                  body={(rowData) => (
                    <>
                      <Button
                        label="Editar"
                        className="p-button-outlined"
                        onClick={() => openSidebar(rowData)}
                        css={comunCss.buttonCustom}
                      />
                    </>
                  )}
                  alignHeader="center"
                />
              </DataTable>
            </div>
          </Content>
        </div>
      </div>

      <Sidebar
        style={{ backgroundColor: "#f0f0f0" }}
        className="p-sidebar-sm"
        visible={selectedContactId !== null}
        position="right"
        dismissable={true}
        onHide={onCloseSidebar}
      >
        <div>
          <UserAvatar
            label={characterAvatar}
            avatarFile={avatarFile}
            onChange={(file) => setAvatarFile(file)}
          />
          <div className="-mt-5">
            <div
              css={comunCss.boxProfileHead}
              style={{ borderBottom: "solid 1px #E5E5E5" }}
            >
              <div
                css={comunCss.TitleContent}
                className="flex align-items-center justify-content-center"
              >
                {contactDetails.name}
              </div>
              <span
                css={comunCss.idColor}
                className="flex align-items-center justify-content-center text-base"
              >
                ID {contactDetails.id}
              </span>
            </div>
            <div>
              <span className="field mt-2 relative" style={{ top: "-10px" }}>
                <label
                  css={comunCss.inputLabelDefault}
                  style={{ background: "none" }}
                  htmlFor="userName"
                >
                  Nome
                </label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      className="w-full"
                      css={comunCss.noBorder}
                      style={{ background: "#F0F0F0" }}
                      id="userName"
                      value={contactDetails.name}
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          name: e.target.value
                        });
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </span>
              {errors.name?.message ? (
                <div style={{ color: "#AA3939" }}>{errors.name.message}</div>
              ) : null}
            </div>
            <div>
              <span className="field mt-2 relative" style={{ top: "-10px" }}>
                <label
                  css={comunCss.inputLabelDefault}
                  style={{ background: "none" }}
                  htmlFor="userEmail"
                >
                  E-mail
                </label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      className="w-full"
                      css={comunCss.noBorder}
                      style={{ background: "#F0F0F0" }}
                      id="userEmail"
                      value={contactDetails.email}
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          email: e.target.value
                        });
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </span>
              {errors.email?.message && (
                <div style={{ color: "#AA3939", marginTop: "5px" }}>
                  {errors.email.message}
                </div>
              )}

              <span className="field mt-2 relative" style={{ top: "-10px" }}>
                <label
                  css={comunCss.inputLabelDefault}
                  style={{ background: "none" }}
                  htmlFor="userPhone"
                >
                  Telefone
                </label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      {...field}
                      className="w-full"
                      css={comunCss.noBorder}
                      style={{ background: "#F0F0F0" }}
                      id="userPhone"
                      value={contactDetails.phone}
                      onChange={(e) => {
                        setContactDetails({
                          ...contactDetails,
                          phone: e.target.value
                        });
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </span>
              {errors.phone?.message && (
                <div style={{ color: "#AA3939", marginTop: "5px" }}>
                  {errors.phone.message}
                </div>
              )}
            </div>
          </div>
          <Button
            title="Atualizar Colaborador"
            label="SALVAR"
            onClick={handleSubmit(sendUpdate)}
            icon="pi pi-check-circle"
            css={comunCss.saveBtn}
            className="saveBTN p-button-raised p-button-lg mt-3 p-button-text w-full text-blue-800"
            permission={["contact_edit"]}
          />
        </div>
      </Sidebar>
    </ContainerPage>
  );
};

export default ContactsList;
