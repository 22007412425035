import { css } from "@emotion/react";
import { Galleria } from "primereact/galleria";
import { Fragment, useEffect, useRef, useState } from "react";
import { botRed } from "../../assets";
import { AuthContextType, useAuth } from "../../auth/auth-provider";
import Avatar from "../avatar";
import { Tooltip } from "primereact/tooltip";
import { format, isToday, isAfter } from "date-fns";
import { iFile } from "../use-omnichannel";
import { AudioPlayer } from "./AudioPreview";
import { VideoPreview } from "./VideoPreview";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addUrls, removeUrls } from "../../redux/slices/flowInteractions";
import { DropdownDeleteMessage } from "./styles";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import OmnichannelService from "../../services/omnichannelService";
import { useToast } from "../../components";

const getMaskedDate = (data: Date): string => {
  const messageDate = new Date(data);
  const currentDate = new Date();

  if (isToday(messageDate)) {
    return format(messageDate, "HH:mm");
  } else if (isAfter(currentDate, messageDate)) {
    return format(messageDate, "dd/MM/yyyy (HH:mm)");
  }

  return "";
};

export interface StatusMessageProps {
  message: string;
}

export const StatusMessage = ({ message }: StatusMessageProps) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-bottom: 10px;
      margin-top: 10px;
    `}
  >
    <div
      css={css`
        background-color: #c4e2ff;
        border-radius: 800px;
        display: flex;
        font-size: 12px;
        padding: 5px 10px;
        z-index: 1;
      `}
    >
      {message}
    </div>

    <div
      css={css`
        border-bottom: 1px solid #e5e5e5;
        width: 100%;
        position: absolute;
        top: 50%;
      `}
    ></div>
  </div>
);

const URL_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/iu;

export interface MessageProps {
  id: number;
  message: string;
  robotName: string;
  chatName: string;
  reply: boolean;
  isHumanResponder: boolean;
  files: iFile[];
  date: Date;
}

const FileImagePreview = ({ file }: { file: iFile }) => {
  const { mime, url } = file;
  const galleriaRef = useRef<Galleria>(null);
  const newUrls = useAppSelector((state) => state.flowInteractions.newUrl);
  const dispatch = useAppDispatch();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    dispatch(addUrls([file.url]));

    return () => {
      dispatch(removeUrls());
    };
  }, [file]);

  const handleImageClick = (url: string) => {
    const index = newUrls.indexOf(url);
    if (index !== -1) {
      setActiveIndex(index);
      galleriaRef?.current?.show();
    }
  };

  if (mime.startsWith("image")) {
    return (
      <div
        css={css`
          width: 100%;
          display: flex;
          justify-content: center;
        `}
      >
        <img
          src={file.url}
          height={200}
          css={css`
            cursor: pointer;
            max-width: 100%;
          `}
          onClick={() => handleImageClick(file.url)}
        />
        <Galleria
          ref={galleriaRef}
          value={newUrls}
          circular={true}
          fullScreen={true}
          showItemNavigators={true}
          showThumbnails={true}
          numVisible={6}
          activeIndex={activeIndex}
          onItemChange={(e) => setActiveIndex(e.index)}
          item={(item) => (
            <img
              src={item}
              style={{ width: "90vw", height: "75vh", objectFit: "contain" }}
            />
          )}
          thumbnail={(item) => <img src={item} width={80} height={60} />}
          style={{ maxHeight: "80%", maxWidth: "80%" }}
        />
      </div>
    );
  }

  return null;
};

const FileLinkURL = ({
  filename,
  file
}: {
  filename: string;
  message: string;
  file?: iFile;
}) => {
  return (
    <a href={file?.url} target="_blank" rel="noreferrer">
      {filename}
    </a>
  );
};

export const Message = ({
  id,
  message,
  isHumanResponder,
  files,
  robotName,
  chatName,
  reply,
  date
}: MessageProps) => {
  let messageContent;

  const { user } = useAuth() as AuthContextType;
  const [showModalDelete, setShowModalDelete] = useState(false);
  const { deleteMessages } = OmnichannelService();
  const toast = useToast();
  const whatsappQrCode = useAppSelector(
    (state) => state.omnichannel.whatsappQrCode
  );
  const [textMessageDeleted, setTextMessageDeleted] = useState(false);

  if (files) {
    messageContent = message;
  } else {
    message.split("\n").map((m, index) => {
      const content = m.split(" ").map((mm, mIndex) =>
        URL_REGEX.test(mm) ? (
          <Fragment key={mIndex}>
            <a
              href={
                mm.includes("https://") || mm.includes("http://")
                  ? mm
                  : `https://${mm}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {mm}
            </a>{" "}
          </Fragment>
        ) : (
          <Fragment key={mIndex}>{mm} </Fragment>
        )
      );
      return <div key={index}>{content}</div>;
    });
  }

  const cancelDeleteMessage = () => {
    setShowModalDelete(false);
  };

  const handleDeleteMessage = async () => {
    try {
      await deleteMessages(id);
      setTextMessageDeleted(true);
      toast?.current?.show({
        life: 3000,
        severity: "success",
        summary: "Sucesso",
        detail: "Mensagem apagada com sucesso!",
        sticky: false
      });
    } catch (error) {
      console.error(error);
      toast?.current?.show({
        life: 3000,
        severity: "error",
        summary: "Erro.",
        detail:
          "Erro ao tentar apagar a mensagem. Por favor, tente mais tarde.",
        sticky: false
      });
    }
    setShowModalDelete(false);
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: ${reply ? "flex-start" : "flex-end"};
        margin-bottom: 10px;
        margin-top: 10px;
        width: 100%;
      `}
    >
      <div
        css={css`
          align-items: center;
          display: flex;
          gap: 10px;
        `}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          reply ? (
            isHumanResponder ? (
              <div className="user-message-tooltip" data-pr-position="bottom">
                <Avatar name={user.name} />
                <Tooltip target=".user-message-tooltip"> {user.name} </Tooltip>
              </div>
            ) : (
              <div>
                <img
                  className="bot-message-tooltip"
                  data-pr-position="bottom"
                  src={botRed}
                  css={css`
                    height: 43px;
                    width: 43px;
                  `}
                />

                <Tooltip target=".bot-message-tooltip"> {robotName} </Tooltip>
              </div>
            )
          ) : null
        }
        {/* <VideoPreview videoURL="https://youtu.be/ZaYvwn9nBD4"/> */}

        <div
          css={css`
            align-items: ${reply ? "flex-start" : "flex-end"};
            background: ${reply
              ? "#f0eff5"
              : "linear-gradient(90deg, #046af3 0%, #4694fe 100%)"};
            border-radius: ${reply
              ? "16px 16px 16px 0px"
              : "16px 16px 0px 16px"};
            ${reply ? "" : "color: #ffffff;"}
            display: flex;
            max-width: 40vw;
            flex-direction: column;
            gap: 5px;
            padding: 10px;
            a {
              ${reply ? "" : "color: #ffffff;"};
            }
            display: flex;

            @media screen and (max-width: 1024px) {
              max-width: 60vw;
            }

            @media screen and (max-width: 599px) {
              max-width: 70vw;
            }
          `}
        >
          <div style={{ width: "100%" }}>
            {Array.isArray(files) && files.length > 0 && whatsappQrCode
              ? !textMessageDeleted && (
                  <DropdownDeleteMessage>
                    {message !== "Mensagem apagada" && (
                      <Dropdown
                        onChange={(e) => {
                          if (e.value === "delete") {
                            setShowModalDelete(true);
                          }
                        }}
                        options={[
                          { label: "Apagar mensagem", value: "delete" }
                        ]}
                        style={{
                          background: reply
                            ? "#f0eff5"
                            : "linear-gradient(90deg, #046af3 0%, #4694fe 100%)",
                          border: "none",
                          height: "15px",
                          marginTop: "-5px",
                          width: "100%"
                        }}
                        placeholder=""
                        className="custom-dropdown"
                      />
                    )}
                  </DropdownDeleteMessage>
                )
              : null}
          </div>
          {Array.isArray(files) && files.length > 0 && textMessageDeleted ? (
            <div
              style={{
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflow: "break-work",
                width: "100%"
              }}
            >
              Mensagem apagada
            </div>
          ) : (
            files?.map((file, idx) => {
              if (file.mime.startsWith("image")) {
                return <FileImagePreview key={idx} file={file} />;
              }
              if (file.mime.startsWith("audio")) {
                return <AudioPlayer url={file.url} key={idx} />;
              }
              if (file.mime.startsWith("video")) {
                return <VideoPreview videoURL={file.url} key={idx} />;
              }
              return (
                <FileLinkURL
                  filename={file.fileName}
                  message={message}
                  key={idx}
                  file={file}
                />
              );
            })
          )}
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {!textMessageDeleted && whatsappQrCode && (
              <DropdownDeleteMessage>
                {message !== "Mensagem apagada" && (
                  <Dropdown
                    onChange={(e) => {
                      if (e.value === "delete") {
                        setShowModalDelete(true);
                      }
                    }}
                    options={[{ label: "Apagar mensagem", value: "delete" }]}
                    style={{
                      background: reply
                        ? "#f0eff5"
                        : "linear-gradient(90deg, #046af3 0%, #4694fe 100%)",
                      border: "none",
                      height: "15px",
                      marginTop: "-5px",
                      width: "100%"
                    }}
                    placeholder=""
                  />
                )}
              </DropdownDeleteMessage>
            )}
            <div
              style={{
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflow: "break-work",
                width: "100%"
              }}
            >
              {textMessageDeleted ? "Mensagem apagada" : message}
            </div>
          </div>
          {/* <pre>{message}</pre>
          {/* {files ? (
            <FileLink message={message} file={files[0]?.url} />
          ) : (
            // <div>{messageContent}</div>
          )} */}
          <div
            css={css`
              font-size: 12px;
            `}
          >
            {getMaskedDate(date)}
          </div>
        </div>

        {reply ? null : (
          <div className="chat-message-tooltip" data-pr-position="bottom">
            <Avatar name={chatName} />
            <Tooltip target=".chat-message-tooltip"> {chatName} </Tooltip>
          </div>
        )}
      </div>
      <Dialog
        visible={showModalDelete}
        onHide={cancelDeleteMessage}
        header="Confirmar exclusão"
        draggable={false}
        footer={
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              label="Apagar"
              className="p-button-danger ml-2 mr-2"
              onClick={handleDeleteMessage}
            />
            <Button
              label="Cancelar"
              className="ml-2 mr-2"
              onClick={cancelDeleteMessage}
            />
          </div>
        }
      >
        <p>Tem certeza de que deseja apagar esta mensagem?</p>
      </Dialog>
    </div>
  );
};

export interface MessageButtonProps {
  label: string;
}

const MessageButton = ({ label }: MessageButtonProps) => (
  <div
    css={css`
      background-color: #ffffff;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      padding: 8px;
      max-width: 90vw;
      overflow-wrap: break-word;
      white-space: normal;
    `}
  >
    {label}
  </div>
);

export default MessageButton;
