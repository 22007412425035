import { css } from "@emotion/react";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { JsonObject } from "react-use-websocket/dist/lib/types";
import ChatContactInfos from "../chat-infos/chat-contact-infos";
import { Message, iDialogMessagesFile } from "../use-omnichannel";
import Input from "./input";
import Messages from "./messages";
import Topbar from "./topbar";
import { TransferModal } from "./Transfer";
import { setLastMessageSent } from "../../redux/slices/sidebar";
import { useAppDispatch } from "../../redux/hooks";

export interface ChatProps {
  dialog: iDialogMessagesFile;
  onDialogFinalize: () => void;
  shouldScroll: boolean;
  onShouldScroll: (shouldScroll: boolean) => void;
}

export interface ChatMessageButton extends JsonObject {
  id: number;
  position: number;
  label: string;
  type: string;
  link: string;
}

export interface ChatMessageJson extends JsonObject {
  id: number;
  dialogId: number;
  sender: string;
  message: string;
  dateTime: string;
  dialogToken: string;
  file?: {
    id: number;
    mime: string;
    name: string;
    url: string;
  };
  files?: {
    id: number;
    mime: string;
    name: string;
    url: string;
  }[];
  buttons: ChatMessageButton[];
}

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const Chat = ({ dialog, onDialogFinalize, shouldScroll }: ChatProps) => {
  const { lastJsonMessage, sendJsonMessage } = useWebSocket<ChatMessageJson>(
    process.env.NODE_ENV === "development"
      ? `ws://${process.env.OMNICHANNEL_URL}/omnichannel/${dialog.id}`
      : `wss://${process.env.OMNICHANNEL_URL}/omnichannel/${dialog.id}`,
    {
      retryOnError: true,
      reconnectAttempts: 10,
      reconnectInterval: 5_000,
      heartbeat: {
        interval: 10_000,
        message: `{ "ping": true }`,
        returnMessage: "pong",
        timeout: 60_000
      }
    }
  );
  const [infoSideBarState, setExpandedInfo] = useState(false);
  const [dialogInfo, setDialogInfo] = useState<iDialogMessagesFile>(dialog);
  const [messageHistory, setMessageHistory] = useState<Message[]>(
    dialog.messages
  );
  const orderedMessages = messageHistory?.sort((a, b) => a.id - b.id);

  const [screenMobile, setScreenMobile] = useState(false);

  const messageRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const scrollToBottom = () => {
    if (messageRef.current) {
      messageRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    }
  };

  const onShouldSroll = (set: boolean) => {
    if (set) {
      scrollToBottom();
    }
  };

  useEffect(() => {
    setDialogInfo(dialog);
    setMessageHistory(dialog?.messages);
    setTimeout(() => scrollToBottom(), 100);
    scrollToBottom();
  }, [dialog.id]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const lastJsonMessageTyped = lastJsonMessage as unknown as Message;

      if (lastJsonMessage?.file?.name?.length) {
        lastJsonMessageTyped.files = [
          {
            id: String(lastJsonMessage?.file.id),
            mime: lastJsonMessage?.file.mime,
            fileName: lastJsonMessage?.file.name,
            url: lastJsonMessage?.file.url
          }
        ];
      } else if (lastJsonMessage?.files?.length) {
        lastJsonMessageTyped.files = lastJsonMessage?.files.map((el) => ({
          id: String(el.id),
          mime: el.mime,
          fileName: el.name,
          url: el.url
        }));
      }

      setMessageHistory((prev) => {
        const messageId = prev.findIndex(
          (msg) => msg.id === lastJsonMessageTyped.id
        );

        if (messageId !== -1) {
          const updatedMessages = [...prev];
          updatedMessages[messageId] = lastJsonMessageTyped;
          return updatedMessages;
        }
        return [...prev, lastJsonMessageTyped];
      });

      setTimeout(() => scrollToBottom(), 100);
      scrollToBottom();
    }
  }, [shouldScroll, lastJsonMessage]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setScreenMobile(true);
      } else {
        setScreenMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          position: relative;
        `}
      >
        <Topbar dialog={dialog} onFinalize={onDialogFinalize} />
        <TransferModal />

        <Messages
          dialogInfo={dialogInfo}
          dialog={orderedMessages}
          shouldScroll={shouldScroll}
          onShouldScroll={() => onShouldSroll(false)}
          messageRef={messageRef}
        />
        <Input
          dialog={dialog}
          onSendMessage={(message) => {
            sendJsonMessage({ message });
            dispatch(setLastMessageSent(message));
            onShouldSroll(true);
          }}
          onFilesAttached={async (files) => {
            for (const file of files) {
              // eslint-disable-next-line no-await-in-loop
              const base64File = await toBase64(file);
              sendJsonMessage({
                message: file.name,
                file: base64File as string
              });
            }
            // await new Promise((resolve) => setTimeout(resolve, 500));
            onShouldSroll(true);
          }}
        />
      </div>

      {!screenMobile && (
        <div
          css={css`
            display: flex;
            align-items: center;
            right: 0;
            z-index: 1;
            position: absolute;
          `}
        >
          <Button
            className="p-button-secondary openCloseButtonChatInfos"
            onClick={() => {
              setExpandedInfo((previous) => !previous);
            }}
            label={infoSideBarState ? ">" : "<"}
            css={
              infoSideBarState
                ? css`
                    position: static !important;
                    margin-bottom: 14vh;
                  `
                : css`
                    position: fixed !important;
                  `
            }
          />
          <div
            css={css`
              display: flex;
              right: 0;
            `}
          >
            {infoSideBarState ? <ChatContactInfos dialog={dialog} /> : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
