import styled from "@emotion/styled";

export const Button = styled.button`
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  width: 9.875rem;
  height: 2.25rem;
  background-color: #046af3;
  color: #fff;
  border-radius: 800px;
  :hover {
    cursor: pointer;
    background-color: #023985;
  }
  :disabled {
    background-color: #383838;
    cursor: normal;
  }
`;

export const DropdownDeleteMessage = styled.div`
  display: flex;
  width: calc(100% + 8px);
`;
