import { useFetch2 } from "../util";

export interface FindDialogsOutput {
  id: number;
  name: string;
  lastMessageDateTime: string;
  lastMessage: string;
  unreadMessageTotal: number;
  plataform: string;
}

export interface FindDialogsPageableOutput {
  id: number;
  name: string;
  lastMessageDateTime: string;
  lastMessage: string;
  unreadMessageTotal: number;
  plataform: string;
}

export interface FindDialogMessageButtonOutput {
  id: number;
  position: number;
  label: string;
  type: string;
  link: string;
}

export interface FindDialogMessageOutput {
  id: number;
  message: string;
  dateTime: string;
  sender: string;
  file: string;
  buttons: FindDialogMessageButtonOutput[];
}

export interface iFile {
  id: string;
  fileName: string;
  url: string;
  mime: string;
}

interface Buttons2 {
  id: number;
  position: number;
  label: string;
  type: string;
  link: string;
}

interface Button {
  id: number;
  position: number;
  label: string;
  type: string;
  link?: string;
}
export interface Message {
  id: number;
  message: string;
  sender: string;
  dateTime: string;
  file: string;
  buttons: (Button | Buttons2)[];
  files: iFile[];
}
export interface iDialogMessagesFile {
  id: number;
  name: string;
  botName: string;
  dateTime?: string;
  closedAt?: string;
  platform: string;
  messages: Message[];
  status: string;
  token: string;
  phone: string;
  transferredToThirdpartySystem: boolean;
  transferred: boolean;
  userTransferredId: number;
  whatsappQrCode?: boolean;
}

export interface FindDialogOutput {
  id: number;
  name: string;
  botName: string;
  dateTime: string;
  platform: string;
  status: string;
  messages: FindDialogMessageOutput[];
}

export interface FindBotsList {
  id: number;
  name: string;
}

const useOmnichannel = () => {
  const request = useFetch2();

  const findUnreadRooms = async (data?: {
    workspace?: number;
    name?: string;
    status?: string;
  }): Promise<number> => {
    const params = new URLSearchParams();

    if (data?.workspace) {
      params.append("workspace", `${data.workspace}`);
    }

    if (data?.name) {
      params.append("name", `${data.name}`);
    }

    if (data?.status) {
      params.append("status", `${data.status}`);
    }

    return request(`/api/omnichannel/unreadRooms?${params}`, {
      method: "GET"
    });
  };

  const findDialogs = async (data?: {
    workspace?: number;
    bot?: number;
    name?: string;
    status?: string;
  }): Promise<FindDialogsOutput[]> => {
    const params = new URLSearchParams();

    if (data?.workspace) {
      params.append("workspace", `${data.workspace}`);
    }

    if (data?.name) {
      params.append("name", `${data.name}`);
    }

    if (data?.status) {
      params.append("status", `${data.status}`);
    }

    if (data?.bot && data?.bot > 0) {
      params.append("bots", `${data.bot}`);
    }

    return request(`/api/omnichannel/dialogs?${params}`, {
      method: "GET"
    });
  };

  const findDialogsPageable = async (data?: {
    workspace?: number;
    bot?: number;
    limit?: number;
    offset?: number;
    name?: string;
    status?: string;
  }): Promise<{ result: FindDialogsPageableOutput[]; total: number }> => {
    const params = new URLSearchParams();

    if (data?.workspace) {
      params.append("workspace", `${data.workspace}`);
    }

    if (data?.name) {
      params.append("name", `${data.name}`);
    }

    if (data?.status) {
      params.append("status", `${data.status}`);
    }

    if (data?.bot && data?.bot > 0) {
      params.append("bots", `${data.bot}`);
    }

    if (data?.limit) {
      params.append("limit", `${data.limit}`);
    }

    if (data?.offset) {
      params.append("offset", `${data.offset}`);
    } else {
      params.append("offset", `${0}`);
    }

    return request(`/api/omnichannel/dialogs-pageable?${params}`, {
      method: "GET"
    });
  };

  const findBotsList = async (data: {
    workspace?: number;
    companyId?: number;
  }): Promise<{ result: FindBotsList[] }> => {
    const body = {
      companyId: data.companyId,
      workspaces: [data.workspace],
      offset: 0,
      limit: 100
    };

    return request(
      `/api/bots/list`,
      {
        method: "POST"
      },
      {
        ...body
      }
    );
  };

  const findDialog = async (dialogId: number): Promise<FindDialogOutput> => {
    return request(`/api/omnichannel/dialogs/${dialogId}`, {
      method: "GET"
    });
  };

  const markAsRead = async (dialogId: number) => {
    return request(`/api/omnichannel/dialogs/markAsRead/${dialogId}`, {
      method: "POST"
    });
  };

  const finalize = async (dialogId: number) => {
    return request(`/api/omnichannel/dialogs/finalize/${dialogId}`, {
      method: "POST"
    });
  };

  return {
    findUnreadRooms,
    findDialogs,
    findDialogsPageable,
    findDialog,
    markAsRead,
    finalize,
    findBotsList
  };
};

export default useOmnichannel;
