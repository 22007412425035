import { iDialogMessagesFile } from "../omnichannel/use-omnichannel";
import { useFetch2 } from "../util";

export interface ITransferOmniChannelDialog {
  dialogId: number;
  workspaceId: number;
  userId: number;
  transferToThirdPartySystem: boolean;
}
interface IUserInfo {
  name: string;
  createdAt: string;
  phone: string;
  email: string;
}
const OmnichannelService = () => {
  const request = useFetch2();

  const findDialogFilesURL = async (
    dialogId: number
  ): Promise<iDialogMessagesFile> => {
    return request(`/api/omnichannel/dialogs/${dialogId}/files`, {
      method: "GET"
    });
  };

  const transferOminichannelDialog = async (
    data: ITransferOmniChannelDialog
  ): Promise<{ ok: true }> => {
    return request(
      `/api/omnichannel/dialogs/transfer`,
      { method: "POST" },
      data
    );
  };
  const getContact = async (dialogId: number): Promise<IUserInfo> => {
    return request(`/api/omnichannel/dialogs/${dialogId}/contact`, {
      method: "GET"
    });
  };
  const joinDialog = async (dialogId: number): Promise<IUserInfo> => {
    return request(`/api/omnichannel/dialogs/${dialogId}/join`, {
      method: "POST"
    });
  };
  const openDialog = async (dialogId: number): Promise<IUserInfo> => {
    return request(`/api//omnichannel/dialogs/${dialogId}/open`, {
      method: "POST"
    });
  };

  const deleteMessages = async (dialogId: number) => {
    return request(`/api/omnichannel/dialogs/message/${dialogId}`, {
      method: "DELETE"
    });
  };

  return {
    findDialogFilesURL,
    transferOminichannelDialog,
    getContact,
    joinDialog,
    openDialog,
    deleteMessages
  };
};

export default OmnichannelService;
