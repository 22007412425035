import { configureStore } from "@reduxjs/toolkit";
import nodesReducer from "./slices/nodes";
import edgesReducer from "./slices/edges";
import flowAttributesReducer from "./slices/flowupdates";
import flowInteractionsReducer from "./slices/flowInteractions";
import UserReducer from "./slices/user";
import SidebarReducer from "./slices/sidebar";
import BotReducer from "./slices/bot";
import WorkspacesReducer from "./slices/workspaces";
import ChatbotsReducer from "./slices/chatbots";
import flowUpdatesReducer from "./slices/flowupdates";
import omnichannelReducer from "./slices/omnichannel";

export const store = configureStore({
  reducer: {
    nodes: nodesReducer,
    edges: edgesReducer,
    flowAttributes: flowAttributesReducer,
    flowInteractions: flowInteractionsReducer,
    flowUpdates: flowUpdatesReducer,
    user: UserReducer,
    sidebar: SidebarReducer,
    bot: BotReducer,
    workspaces: WorkspacesReducer,
    chatbots: ChatbotsReducer,
    omnichannel: omnichannelReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
