import { Payload } from "./../../services/botDialogTest";
import { iWorkspaces } from "../../types/iWorkspaces";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface FlowChanges {
  whatsappQrCode: boolean;
}

const initialState: FlowChanges = {
  whatsappQrCode: false
};

const omnichannelSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    setWhatsappQrCode(state, action: PayloadAction<boolean>) {
      state.whatsappQrCode = action.payload;
    }
  }
});

export const { setWhatsappQrCode } = omnichannelSlice.actions;

export default omnichannelSlice.reducer;
